import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { CountryCode } from "@smartrr/shared/countryCode";
import { IPurchaseStateLineItem } from "./CustomerPurchaseLineItem";
import { ScheduledDeliveryGroupWithPurchSt } from "./scheduleUtils";
import { IPurchaseStateDiscount } from "../../interfaces/Discount";
import { SubscriptionContractSubscriptionStatus, SubscriptionDeliveryMethod } from "../../shopifyGraphQL/api";
import { PaginationResult } from "../../utils/paginatedQuery";
import { BigIntString } from "../BigIntString";
import { ICustomerRelationshipSellingOrgShallow } from "../CustomerRelationship";
import { IGift } from "../Gift";
import { ISODateString } from "../ISODateString";
import { IMailingAddressCreate, IMailingAddressJson } from "../MailingAddress";
import { IPriceFieldsSharedWithOrderAndPurchSt } from "../Order";
import { IOrganization } from "../Organization";
import { IPaymentMethod } from "../PaymentMethod";
import { IPurchasable } from "../Purchasable";
import { IPurchasableVariant } from "../PurchasableVariant";
import { ISchedule } from "../Schedule";
import { ICreateEntity, IShallowEntity, IShopifyMirroredEntityFields } from "../shared/SharedEntityFields";

export interface IPurchaseState extends IShopifyMirroredEntityFields, IPriceFieldsSharedWithOrderAndPurchSt {
  purchaseStateStatus: `${PurchaseStateStatus}` | PurchaseStateStatus;
  schedule: ISchedule;

  gifts?: IGift[];

  createdViaCustomerPortal: boolean;
  initialSubmissionDate: ISODateString; // this is needed as its own field for cohort analysis (defines cohort)
  externalSubscriptionCreatedDate?: ISODateString;
  cancelledAt?: ISODateString;
  pausedAt?: ISODateString;
  unpauseDate?: ISODateString;
  externalSubscriptionCancelledAt?: ISODateString;
  nextBillingDate?: ISODateString;
  nextOrderDate?: ISODateString;
  isRepeatCustomer: boolean;
  organization: IOrganization;

  /*
    the payments corresponding to the order
  */
  pmtMth: IPaymentMethod;

  // pricing related columns
  sellingPlanId?: string;

  currency: CurrencyCode;

  totalEstimatedTax?: BigIntString; // TODO: consider estimating from previous order
  totalShippingDiscount?: BigIntString;
  totalEstimatedNet: BigIntString; // estimated because it does not include taxes

  stLineItems: IPurchaseStateLineItem[];
  discounts: IPurchaseStateDiscount[];

  shippingAddress: IMailingAddressJson;

  externalSubscriptionId?: string;
  externalSubscriptionType?: `${PurchaseStateExternalSubscriptionType}` | PurchaseStateExternalSubscriptionType;
  externalSubscriptionStatus?: `${PurchaseStateStatus}` | PurchaseStateStatus;
  originalExternalSubscriptionStatus?: `${PurchaseStateStatus}` | PurchaseStateStatus;

  containsBundle?: boolean;
  deliveryMethod?: SubscriptionDeliveryMethod;

  subProperties?: ISubscriptionProperties | null;
  hasUsedRetentionDiscount?: boolean;
}

export interface IPurchaseStateTotals {
  totalFromLineItems: BigIntString;
  totalLineItemsDiscount: BigIntString;
  totalLineItemsAfterDiscount: BigIntString;
  totalShipping: BigIntString;
  totalShippingDiscount?: BigIntString;
  totalDiscount: BigIntString;
  totalEstimatedNet: BigIntString;
}

export interface IUnavailableVariantsAndDeliveries {
  purchasables: IPurchasable[];
  vnts: IPurchasableVariant[];
  deliveries: ScheduledDeliveryGroupWithPurchSt<IPurchaseState>[];
}
export interface IPurchaseStateWithCustomerRelationship extends IPurchaseState {
  custRel?: ICustomerRelationshipSellingOrgShallow;
}

export type PurchaseStateAndDelivery = ScheduledDeliveryGroupWithPurchSt<IPurchaseState>;

export interface IPurchaseStateCSV {
  "Subscription ID": string | undefined;
  "Upcoming Order": ISODateString;
  Customer: string | undefined;
  "Customer Email": string | undefined;
  "Est. Total": string | undefined;
  Items: string | undefined;
  Status: string;
  "Created Date": ISODateString;
}

export enum SubscriptionProperties {
  billingFailedMessage = "billingFailedMessage",
  billingFailedNotificationCount = "billingFailedNotificationCount",
  lastBilledOrderId = "lastBilledOrderId",
  nextOrderDateChanged = "nextOrderDateChanged",
  orderNote = "orderNote",
  phoneCountryCode = "phoneCountryCode",
  sendNow = "sendNow",
}

export interface ISubscriptionProperties {
  billingFailedMessage?: string | null;
  billingFailedNotificationCount?: number;
  lastBilledOrderId?: string;
  nextOrderDateChanged?: boolean;
  orderNote?: string;
  phoneCountryCode?: CountryCode;
  sendNow?: boolean;
  shippingCalculationWarning?: IShippingCalculationWarning | null;
  remainingSpecialRewards?: number;
}

export interface IShippingCalculationWarning {
  type: string;
  errorMessage?: string;
}

export type IPurchaseStateCreate = ICreateEntity<IPurchaseState>;
export type IPurchaseStateShallow = IShallowEntity<IPurchaseState>;

export type IPurchaseStateWithCustomerRelationshipPaginatedResponse =
  PaginationResult<IPurchaseStateWithCustomerRelationship>;

export type PurchaseStateStatus =
  /** Active subscription contract. */
  | SubscriptionContractSubscriptionStatus.Active
  /** Paused subscription contract. */
  | SubscriptionContractSubscriptionStatus.Paused
  /** Cancelled subscription contract. */
  | SubscriptionContractSubscriptionStatus.Cancelled
  /** Expired subscription contract. */
  | SubscriptionContractSubscriptionStatus.Expired
  /** Failed subscription contract. */
  | SubscriptionContractSubscriptionStatus.Failed;

export enum PurchaseStateExternalSubscriptionType {
  ReCharge = "RECHARGE",
}

export type PurchaseStateCreateShippingInfoInput = Pick<
  IMailingAddressCreate,
  | "email"
  | "address1"
  | "address2"
  | "city"
  | "countryCode"
  | "firstName"
  | "lastName"
  | "phone"
  | "provinceCode"
  | "zip"
>;

export interface IPurchaseStateReducerState {
  isInitialized: boolean;
  isLoading: boolean;
  nextShipmentDateLoading: boolean;
  isSkipLoading: boolean;
  isPauseLoading: boolean;
  isCancelLoading: boolean;
  skipLoading: Date | null;
  purchaseStates: IPurchaseState[];
  unavailableVariantsAndDeliveries: IUnavailableVariantsAndDeliveries;
}

/**
 * Converts a subscription contract status string into its corresponding enum value.
 * If provided status is not present on this list, status shoul d be considered as ACTIVE because
 * the subscription will be created as paused anyway.
 *
 * @param {string} status - The subscription contract status string to convert.
 * @returns {SubscriptionContractSubscriptionStatus} The corresponding enum value of the subscription contract status.
 */
export function getSubscriptionContractStatus(status: string): SubscriptionContractSubscriptionStatus {
  switch (status) {
    case "active": {
      return SubscriptionContractSubscriptionStatus.Active;
    }
    case "paused": {
      return SubscriptionContractSubscriptionStatus.Paused;
    }
    case "cancelled": {
      return SubscriptionContractSubscriptionStatus.Cancelled;
    }
    case "expired": {
      return SubscriptionContractSubscriptionStatus.Expired;
    }
    case "failed": {
      return SubscriptionContractSubscriptionStatus.Failed;
    }
    default: {
      return SubscriptionContractSubscriptionStatus.Active;
    }
  }
}

export const isUpcomingDelivery = (indexFromNext: number) => indexFromNext === 0;
